table {
  max-width: 80vw;
  border-collapse: collapse;
  margin: 0 auto;
  border: 1px solid #007aff;
}

thead {
  background-color: #007aff;
  color: white;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #007aff;
}

tbody tr {
  height: 50px; /* Set the height of the tbody rows */
  color: black;
}

.buttonHover :hover{
  color: white;
}
