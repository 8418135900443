.cont {
  font-family:sans-serif;
  display: grid;
  justify-content: center;
  background-image: url("./bg.png") ;
    background-position: top;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    height: 110%;
    width: 100%;
    z-index: -1;
    transition: 0.8s cubic-bezier(0.2,0.8,0.2,1);
    overflow: hidden;
    background-size: cover;
    min-width: 400px;
}



.cont form {
  display: grid;
  justify-content: center;
  margin: 40px 0px 100px 0px;
  padding: 0px 45px;
  height: fit-content;
  border: 0.5px solid;
  background-color: #114c6735;
  padding-top: 30px;
  border-radius: 50px;
  flex-direction: column;
  box-shadow: inset rgba(255, 255, 255, 0.822) 5px 60px 80px,
    rgba(36, 160, 218, 0.333) 0px 15px 30px; 
   border: 1px solid #249FDA;
   width: 500px;
   height: 600px;
  
}
.field {
  display: grid;
  position: relative;
  margin-top: 5px;
  text-align: center;
  width: 380px;
}
.field input {
  display: block;
  width: 98%;
  padding: 14px 0 14px 43px;
  margin: 5px 0;
  border-radius: 20px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: rgba(237, 239, 241, 0.932);
}
button {
  display: block;
  width: 100%;
  padding: 12px;
  margin: 5px auto;
  border-radius: 30px;
  border-style: none;
  cursor: pointer;
  margin-bottom: 50px;
}
button a {
  text-decoration: none;
  color: rgb(253, 253, 253);
  font-size: 20px;

}
.uname {
  height: 20px;
  width: 21px;
  margin-top: 2px;
  padding-top: 35px;
  padding-left: 14px;
}
.pswd {
  height: 20px;
  width: 23px;
  display: flex;
  padding: 2px 2px;
  padding-top: 37px;
  padding-left: 14px;
}
.cont h1 {
  color: rgba(6, 71, 108, 1);
  text-align: Center;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 40px;
  font-weight: 550;
  line-height: 100%;
}
#btn1 {
  background-color: rgba(255, 133, 51, 1);
  width: 150px;
  height: 50px;
  font-size: 18px;  
  margin-bottom: 15px;
  color: #fff;
}
#btn1:hover {
  transform: translateY(-3px);
  transition: all 0.2s;
}


.register label p {
  color: #000;
  font-size: 16px;
  justify-content: left;
}

#l4 {
  color: #000;
  text-align: center;
  font-size: 15px;
  padding: 40px 0 25px 0;
  text-decoration: none;
}
.cont p {
  text-align: left;
  padding-left: 20px;
  font-size: 12px;
  color: rgb(218, 31, 31);
}
.field img {
  position: absolute;
}
.field label {
  justify-self: left;
  font-weight: 500;
  padding-left: -10px;
}
.middle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #000;
  margin: 5px 0 15px 0;
}
.right label a {
  color: #000;
  text-decoration: none;
}
.right label p {
  color: #000;
  font-size: 16px;
  justify-content: left;
}

@media screen and (max-width: 500px) {
  .cont {
    display: block;
    float: none;
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 1px;
    padding-bottom: 20px;
  }

  .cont h1 {
    margin-top: 1px;
    font-size: 25px;
  }
  .field {
    justify-content: center;
    margin-top: 1px;
  }
  .field input {
    width: 270px;
  }
  .middle {
    font-size: small;
    justify-content: left;
    display: grid;
    margin-top: -10px;
  }
  .field img {
    position: absolute;
    padding-top: 38px;
    padding-left: 68px;
    width: 18px;
    height: 18px;
  }

  .right label a {
    justify-content: left;
  }
  button a {
    font-size: small;
  }
  #btn1 {
    width: 195px;
  }
  #btn2 {
    width: 195px;
  }
  #l4 {
    font-size: small;
  }
  .cont p {
    text-align: center;
  }
}
@media screen and (max-width: 320px) {
  .cont {
    display: block;
    float: none;
    margin-left: 5px;
    margin-right: 5px;
  }
  .field {
    justify-content: center;
  }
  .field input {
    width: 220px;
  }
  .middle {
    font-size: small;
    justify-content: center;
    display: grid;
  }
  .field img {
    position: absolute;
    padding-top: 38px;
    padding-left: 92px;
    width: 18px;
    height: 18px;
  }

  .right label a {
    justify-content: end;
  }
  button a {
    font-size: small;
  }
  #btn1 {
    width: 180px;
  }
  #btn2 {
    width: 180px;
  }
  #l4 {
    font-size: small;
  }
  .cont h1 {
    text-align: center;
    font-size: 25px;
  }
}
