.bg-light-green {
  background-color: #8bc34a;
}
.card {
  border-radius: 20px;
  box-shadow: 0px 0px 10px #ccc;
}
.btn-success {
  background-color: #8bc34a;
  border-color: #8bc34a;
  transition: all 0.3s ease-in-out;
}
.btn-success:hover {
  background-color: #689f38;
  border-color: #689f38;
}
