.custom-btn {
  font-size: 18px;
  border-radius: 3px; 
  margin-top: 20px;
  padding: 1px 2px; 
  transition: background-color 0.3s ease;
  width: 100px;
}

.custom-btn:hover {
  color: #fff;
}
