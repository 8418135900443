.custom-btn {
  font-size: 15px;
  border-radius: 3px;
  margin: 2px;
  padding: 1px 2px;
  transition: background-color 0.3s ease;
}

.custom-btn:hover {
  color: #fff;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

#btn2 {
  background-color: rgba(13, 94, 132, 1);
  color: aliceblue;
  width: 250px;
  margin-top: 20px;

  padding-bottom: 15px;
  font-size: 18px;
}
#btn2:hover {
  transform: translateY(-3px);
  background-color: rgb(6, 61, 86);
  transition: all 0.2s;
}

#EditAdmin {
  background-color: #0d6efd;
  color: #fff;
  border: none;
  width: 50px;
  font-size: 16px;
  margin-right: 5px;
}

#DeleteAdmin {
  width: 60px;
  font-size: 16px;
  margin-left: 10px;
}
