.main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(
    135deg,
    hsla(242, 44%, 47%, 1) 0%,
    hsla(240, 94%, 68%, 1) 38%,
    hsla(318, 79%, 79%, 1) 79%
  );
}

.foget-password-header {
  font-size: 40px;
  color: aliceblue;
}

.header2 {
  font-size: 17px;
  color: aliceblue;
}

.text_input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
